import React, { Suspense } from "react";
import { Route, Routes as Router } from "react-router-dom";
import { privateRoutesList, publicRoutesList } from "./routesList";
import PrivateRoutes from "./privateRoutes";
import { useAuth } from "../store/ContextProvider";
import Splash from "../components/common/Splash";

const Routes = () => {
  const { userData } = useAuth();

  return (
    <Suspense fallback={<Splash />}>
      <Router>
        <Route element={<PrivateRoutes />}>
          {privateRoutesList.map((data, index) => {
            if (userData?.roles[data?.permissionKey]) {
              return (
                <Route key={index} path={data.path} element={data.component} />
              );
            }
          })}
        </Route>

        {publicRoutesList.map((data, i) => (
          <Route key={i} path={data.path} element={data.component} />
        ))}
      </Router>
    </Suspense>
  );
};

export default Routes;

import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import UploadPDFSlice from "./slices/uploadPDF.slice";
import UploadedRigsSlice from "./slices/uploadedRigs.slice";
import RigsSlice from "./slices/rigs.slice";
import ContractorSlice from "./slices/contractors.slice";
import LogsSlice from "./slices/logs.slice";
import UserSlice from "./slices/users.slice";


const mainReducer = combineReducers({
    uploadPDF: UploadPDFSlice.reducer,
    uploadedRigs:UploadedRigsSlice.reducer,
    rigs:RigsSlice.reducer,
    contractors:ContractorSlice.reducer,
    logs:LogsSlice.reducer,
    users:UserSlice.reducer

});

export const store = configureStore({
  reducer: mainReducer,
  devTools: true,
});

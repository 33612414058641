import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import { StyledTableContainer } from "./TableBigCss";

const TableBig = ({
  tableColumn,
  tableBody,
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  totalRecords,
  tableColumnSecondary,
  isForRig = false,
  showSortInCol = false,
  handleSort,
  sortDirection,
  requiredIndex,
  sortIndex,
  tableMinWidth,
}) => {
  const tableHead = useMemo(() => {
    return (
      <TableHead>
        <TableRow hover={true}>
          {tableColumn &&
            tableColumn.map((value, index) => {
              let stickyStyles = {};

              // Apply sticky styles for specific indexes (0, 1, 2, 3)
              if (index === 0) {
                stickyStyles = {
                  position: { md: "sticky" },
                  left: { md: 0 },
                  zIndex: { md: 2 },
                  backgroundColor: { md: "white" },
                };
              } else if (index === 1) {
                stickyStyles = {
                  position: { md: "sticky" },
                  left: { md: 50 },
                  zIndex: { md: 2 },
                  backgroundColor: { md: "white" },
                };
              } else if (index === 2) {
                stickyStyles = {
                  position: { md: "sticky" },
                  left: { md: 220 },
                  zIndex: { md: 2 },
                  backgroundColor: { md: "white" },
                };
              } else if (index === 3) {
                stickyStyles = {
                  position: { md: "sticky" },
                  left: { md: 303 },
                  zIndex: { md: 2 },
                  backgroundColor: { md: "white" },
                };
              }

              return (
                <TableCell
                  key={index}
                  align="left"
                  sx={isForRig ? stickyStyles : ""}
                >
                  {showSortInCol && requiredIndex?.includes(index) ? (
                    <TableSortLabel
                      active={sortIndex === index}
                      // active={true}
                      direction={sortIndex === index ? sortDirection : "asc"} // Use the sortDirection from the parent
                      onClick={() => {
                        // Call the parent's handleSort to update the direction
                        handleSort(
                          sortDirection === "asc" && sortIndex === index
                            ? "desc"
                            : "asc",
                          index,
                          value
                        );
                      }}
                    >
                      <Typography
                        variant="body3"
                        component={"p"}
                        sx={{ whiteSpace: "nowrap" }}
                      >
                        {value}
                      </Typography>
                    </TableSortLabel>
                  ) : (
                    <Typography
                      variant="body3"
                      component={"p"}
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      {value}
                    </Typography>
                  )}
                </TableCell>
              );
            })}
        </TableRow>
      </TableHead>
    );
  }, [tableColumn, sortDirection]);
  const tableHeadSecondary = useMemo(() => {
    return (
      <TableHead>
        <TableRow>
          {tableColumnSecondary?.map((column, i) => (
            <TableCell
              key={i}
              colSpan={column.colspan}
              className="secondaryHeadCell"
            >
              <Typography
                variant="body3"
                component={"p"}
                sx={{ textWrap: "nowrap" }}
              >
                {column.label}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }, [tableColumnSecondary]);
  return (
    <>
      <StyledTableContainer elevation={0}>
        <Table
          sx={{ minWidth: tableMinWidth ? tableMinWidth : 650 }}
          size="medium"
          aria-label="a dense table"
        >
          {/* ************************table head secondary:************************  */}
          {tableHeadSecondary}
          {/* ************************table head:************************  */}
          {tableHead}
          {/* ************************table body:************************  */}
          <TableBody>{tableBody}</TableBody>
          {+totalRecords === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={tableColumn?.length || 5}>
                  Result not found
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            ""
          )}
        </Table>
      </StyledTableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 15, 20]}
        className="pagination"
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </>
  );
};

export default TableBig;

import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import dashboardLogo from "../../assets/images/dashboard_logo.png";
import { drawerList, logOut } from "./DrawerList";
import { useLocation, useNavigate } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { Collapse, IconButton, AppBar, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { removeTokens } from "../../utils/helpers/removeTokens";
import { getUserDetails } from "../../utils/helpers";

const drawerWidth = 300;

const filterAuthRoutes = (routes) => {
  const userDetails = getUserDetails();
  return routes.filter((data) => {
    if (userDetails?.roles[data?.permissionKey]) {
      return true;
    }
    return false;
  });
};

function DashboardLayout({ children }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [drawerListItem, setDrawerListItem] = React.useState(
    filterAuthRoutes(drawerList)
  );
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = getUserDetails();

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleListItem = (path, text) => {
    console.log("path", path);
    console.log("text", text);
    setDrawerListItem((prev) =>
      prev.map((item) =>
        item.text === text
          ? { ...item, extended: !item.extended }
          : { ...item, extended: false }
      )
    );
    if (window.innerWidth <= 900) {
      if (path) {
        handleDrawerClose();
      }
    }
    text !== "Rigs" && navigate(path);
  };

  const handleNestedList = (path) => {
    console.log("path", path);

    navigate(path);
    handleDrawerClose();
  };
  // ********************List of drawer ***********************
  const drawer = (
    <Box
      sx={{
        width: { xs: "100%", md: "80%" },
        backgroundColor: "rgba(1, 34, 30, 1)",
        margin: "auto",
        borderRadius: "20px",
        height: { xs: "auto", sm: "95%" },
        paddingTop: "1.5rem",
        position: { xs: "relative" },
      }}
    >
      {/* *************************************************Close button display on mobile ************************************** */}
      <IconButton
        sx={{
          position: "absolute",
          top: "5px",
          right: "12px",
          display: { md: "none" },
        }}
        onClick={handleDrawerClose}
      >
        <CloseIcon sx={{ color: "#fff" }} />
      </IconButton>

      <div style={{ width: "160px", margin: "auto" }}>
        <img src={dashboardLogo} alt="dashboard_logo" width={"100%"} />
      </div>
      <List sx={{ padding: "10px 10px" }}>
        {userDetails &&
          drawerListItem.map((text, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{
                color: "#fff",
                padding: "0 10px 0 10px",
                display: "block",
                marginBottom: "0",
              }}
            >
              <ListItemButton
                onClick={() => handleListItem(text.to, text.text)}
                selected={location.pathname === text.to}
                sx={{
                  color: location.pathname === text.to ? "black" : "#fff",
                  "&.Mui-selected": {
                    backgroundColor: "#fff",
                    borderRadius: "5px",
                    fontWeight: "700",
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: "#fff",
                  },
                  justifyContent: open ? "initial" : "center",
                }}
              >
                <ListItemText
                  primary={text.text}
                  primaryTypographyProps={{
                    fontWeight: location.pathname === text.to ? "600" : "300",
                  }}
                  sx={{
                    color: location.pathname === text.to ? "#black" : "#fff",
                  }}
                />
                {open &&
                  text?.nestedList &&
                  (text.extended ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
              {open &&
                text?.nestedList?.map((nestedData, i) => (
                  <Collapse
                    in={text.extended}
                    timeout="auto"
                    unmountOnExit
                    key={`${index}_${i}`}
                  >
                    <List component="div" disablePadding>
                      <ListItemButton
                        key={`${index}_${i}`}
                        selected={
                          location.pathname + location.search === nestedData.to
                        }
                        onClick={() => handleNestedList(nestedData.to)}
                        sx={{
                          padding: "3px 0 3px 30px",
                          color:
                            location.pathname + location.search ===
                            nestedData.to
                              ? "black"
                              : "#fff",
                          "&.Mui-selected": {
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                          },
                          "&.Mui-selected:hover": {
                            backgroundColor: "#fff",
                          },
                        }}
                      >
                        <ListItemText
                          primary={nestedData.text}
                          sx={{
                            fontSize: "12px",
                            color:
                              location.pathname + location.search ===
                              nestedData.to
                                ? "#black"
                                : "#fff",
                          }}
                        />
                      </ListItemButton>
                    </List>
                  </Collapse>
                ))}
            </ListItem>
          ))}
      </List>
      <List
        sx={{
          padding: "10px 10px",
          position: { md: "relative", lg: "absolute" },
          bottom: { lg: "3%", xl: "10%" },
        }}
      >
        {logOut.map((text, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{ color: "#fff", padding: "0 10px" }}
          >
            <ListItemButton
              onClick={() => {
                removeTokens();
                navigate("/");
              }}
              sx={{
                color: location.pathname === text.to ? "black" : "#fff",
                "&.Mui-selected": {
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  fontWeight: "700",
                },
                "&.Mui-selected:hover": {
                  backgroundColor: "#fff",
                },
              }}
            >
              <ListItemText
                primary={text.text}
                sx={{
                  color: location.pathname === text.to ? "#black" : "#fff",
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  // ********************End List of drawer ***********************

  return (
    <Box sx={{ display: { xs: "block", sm: "flex" } }}>
      <CssBaseline />
      {/* AppBar for mobile and tablet */}
      <AppBar
        position="fixed"
        sx={{
          display: { xs: "block", md: "none" },
        }}
        elevation={0}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { xs: "block", sm: "block" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {/* Drawer for mobile */}
      <Box
        component="nav"
        sx={{
          width: { md: drawerWidth },
          flexShrink: { md: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerClose}
          onTransitionEnd={handleDrawerTransitionEnd}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              border: "none",
              backgroundColor: "transparent !important",
              opacity: "none",
              boxShadow: "none",
              height: "auto",
              top: "5%",
              left: "3%",
            },
          }}
        >
          {drawer}
        </Drawer>
        {/* Drawer for desktop */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              border: "0",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {/* **********************children*********************************** */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 2, sm: 3, md: 0, xl: 5 },
          pr: { xs: 2, sm: 3, md: 3, xl: 5 },
          pl: 0,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          mt: { xs: 10, sm: 10, md: 3, xl: 0 },
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

DashboardLayout.propTypes = {
  window: PropTypes.func,
};

export default DashboardLayout;

import { createSlice } from "@reduxjs/toolkit";

const UploadedRigsSlice = createSlice({
  name: "uploadedRigs",
  initialState: {
    uploadedRigs: null,
    paginationInfo: {
        page: 0,
        pageSize: 10
      },
      summary:null,
      summaryInfo:{
        page: 0,
        pageSize: 10
      }
  },
  reducers: {
    saveUploadedRigs: (state, action) => {
      state.uploadedRigs = action.payload;
    },
    saveReportSummary: (state, action) => {
      state.summary = action.payload;
    },
    setPaginationPage: (state, action) => {
        state.paginationInfo.page = action.payload;
      },
      setPaginationPageSize: (state, action) => {
        state.paginationInfo.pageSize = action.payload;
      },
    setSummaryPaginationPage: (state, action) => {
        state.summaryInfo.page = action.payload;
      },
      setSummaryPaginationPageSize: (state, action) => {
        state.summaryInfo.pageSize = action.payload;
      },
    
}
})
export default UploadedRigsSlice;
import { createSlice } from "@reduxjs/toolkit";

const RigsSlice = createSlice({
  name: "rigs",
  initialState: {
    allRigs: null,
    paginationInfo: {
      page: 0,
      pageSize: 10,
    },
    rigIds:null,
    rigWells:null,
    lostTimeRigs:null,
    lostTimeRigspagination: {
        page: 0,
        pageSize: 10,
      },
  },
  reducers: {
    saveAllRigs: (state, action) => {
      state.allRigs = action.payload;
    },
    saveRigIds: (state, action) => {
      state.rigIds = action.payload;
    },
    saveRigWells: (state, action) => {
      state.rigWells = action.payload;
    },
    setPaginationPage: (state, action) => {
      state.paginationInfo.page = action.payload;
    },
    setPaginationPageSize: (state, action) => {
      state.paginationInfo.pageSize = action.payload;
    },
    saveLostTimeRigs: (state, action) => {
      state.lostTimeRigs = action.payload;
    },
    setLostTimeRigsPaginationPage: (state, action) => {
      state.lostTimeRigspagination.page = action.payload;
    },
    setLostTimeRigsPaginationPageSize: (state, action) => {
      state.lostTimeRigspagination.pageSize = action.payload;
    },
  },
});
export default RigsSlice;
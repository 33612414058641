import { useEffect, useState } from "react";

export const useDebounce = (value, milliSeconds) => {
  const [debouncedValue, setDeboucedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDeboucedValue(value);
    }, milliSeconds);

    return () => {
      clearTimeout(handler);
    };
  }, [value, milliSeconds]);

  return debouncedValue;
};

import axios from "axios";
import CryptoJS from "crypto-js";
import { ENCRYPTION_KEY, IV } from "../constant";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import moment from "moment";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
// Extend dayjs with the plugins
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
export const postRequest = async (url, payload) => {
  try {
    const response = await axios.post(url, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async function (event) {
      const base64Data = event.target.result;
      resolve(base64Data);
    };
    reader.onerror = function (error) {
      reject("Error reading file:", error);
    };
  });
};

export const formatRigDetailsObject = (rows) => {
  let updateKeyValueFormat = {
    ...rows[0],
    ...rows[0]?.rigDetails,
  };
  delete updateKeyValueFormat.rigDetails;
  return updateKeyValueFormat;
};

export const setUserDetails = (user) => {
  localStorage.setItem("user", encrypt(JSON.stringify(user)));
};
export const getUserDetails = () => {
  const encryptedUser = localStorage.getItem("user");
  if (encryptedUser) {
    try {
      return JSON.parse(decrypt(encryptedUser));
    } catch (e) {
      return null;
    }
  }
  // return JSON.parse(localStorage.getItem("user"));
};
const key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
const iv = CryptoJS.enc.Utf8.parse(IV); // Using the same key as IV for simplicity
const encrypt = (text) => {
  const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};
const decrypt = (cipherText) => {
  try {
    const decrypted = CryptoJS.AES.decrypt(cipherText, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error("Error decrypting data:", error);
    return null;
  }
};

export const exportToExcel = (data, headers = null) => {
  let worksheet;

  if (headers) {
    // Use custom headers mapping if provided
    worksheet = XLSX.utils.json_to_sheet(data, {
      header: Object.keys(headers),
    });
    // Add custom header row with provided header names
    XLSX.utils.sheet_add_aoa(worksheet, [Object.values(headers)], {
      origin: "A1",
    });
  } else {
    // Use default object keys as headers
    worksheet = XLSX.utils.json_to_sheet(data);
  }

  // Create workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Generate Excel buffer
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });

  // Create blob and trigger download
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  });
  saveAs(blob, "data.xlsx");
};

export const getIpAddress = async () => {
  const res = await axios.get("https://api.ipify.org/?format=json");
  return res.data.ip;
};

export const TimeFormat = (time) => {
  return moment(time)?.format(`YYYY-MM-DD, h:mm:ss`);
};

export const userReadableDate = (value) => {
  return moment(value).format(`DD MMM, YYYY`);
};

export const sendDateFormat = (value) => {
  return value ? dayjs(value).format("YYYY-MM-DD") : null;
};

export const dateformatforfromdate = (input, year) => {
  // Split the input string into time and date parts
  try {
    const [time, date] = input?.split("-");

    // Format the time part as "HH:MM"
    const formattedTime = `${time?.slice(0, 2)}:${time?.slice(2)}`;

    // Combine formatted time with the year and date
    return `${formattedTime} ${year}-${date}`;
  } catch (err) {
    console.log("err", err);
  }
};

// Function to calculate the maximum date (1 month after the given start date)
export const getMaxDate = (startDate) => {
  const start = dayjs(startDate);
  const maxDate = start.add(1, "month");
  return maxDate;
};

export const validateDateRange = (startDate, endDate) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  // Calculate the maximum allowed end date (1 month after the start date)
  const maxEndDate = start.add(1, "month");
  // Validate if the end date is within the range from start date to maxEndDate
  // Validate if the end date is within the range from start date to maxEndDate
  return (
    end.isSameOrAfter(start, "day") && end.isSameOrBefore(maxEndDate, "day")
  );
};

export const validateDate = (startDate, endDate) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  // Ensure both startDate and endDate are valid
  if (!start.isValid() || !end.isValid()) {
    return false;
  }

  // Check if the end date is before the start date
  if (end.isBefore(start)) {
    return false;
  }

  return true;
};

export const hasValue = (object) => {
  for (const property in object) {
    let value = `${object[property]}`;
    if (object[property] !== null && value.trim() !== "") {
      return true; // Found a field with a value
    }
  }
  return false; // No field had a value
};

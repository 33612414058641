import reportProceed from "../../assets/images/report_proceed.png";
import lostTimeRigs from "../../assets/images/lost_time_rigs.png";
import clock from "../../assets/svg/clock.svg";
import calender from "../../assets/svg/calendar.svg";
export const logsColumn = [
  "No",
  "User Name",
  "Action",
  "Description",
  "User IP",
  "Login Time",
];

export const allRigsColumn = [
  "View",
  "Drilling Contractor",
  "Rig ID",
  "Report Date",
  "Well",
  "Charge No",
  "Well Bores",
  "Objective",
  "Foreman",
  "Last 24 Hr Operation",
  "Next 24 Hr Operation",
  "Location",
  "Next location",
  "Current Depth",
  "Last CSG Size",
  "MD",
  "TVD",
  "Linear Size",
  "TOL",
  "Linear Size MD",
  "Linear Size TVD",
  "Prev Depth",
  "Days Since Spud Comm Date",
  "Circ",
  "Daily Footage",
  "Distance from DHA Mgate",
  "Rig Foreman VSAT",
  "Action",
];
export const allRigsColumnsKeys = {
  "Drilling Contractor": "rig_Info.drilling_contractor",
  "Rig ID": "rig_number",
  "Report Date": "report_date",
};
export const rigLostTimeColumnsKeys = {
  "Drilling Contractor": "rigs->rig_Info.drilling_contractor",
  "Rig ID": "rigs.rig_number",
  "Report Date": "rigs.report_date",
};

export const rigDetailsColumn = [
  {
    key: "report_date",
    heading: "Report Date",
  },
  {
    key: "rig_number",
    heading: "Rig ID",
  },
  {
    key: "well",
    heading: "Well",
  },
  {
    key: "charge",
    heading: "Charge No.",
  },
  {
    key: "wellbores",
    heading: "Well Bores",
  },
  {
    key: "objective",
    heading: "Objective",
  },
  {
    key: "foreman",
    heading: "Foreman",
  },
  {
    key: "engineer",
    heading: "Engineer",
  },
  {
    manager: "Manager",
    key: "manager",
    heading: "Manager",
  },
  {
    key: "rig_forman_vsat",
    heading: "Rig Foreman VSAT",
  },
  {
    key: "contractor_clerk_vsat",
    heading: "Contractor/Clerk VSAT",
  },
  {
    key: "last_24_hr_operation",
    heading: "Last 24 hr operations",
  },
  {
    key: "next_24_hr_plan",
    heading: "Next 24 hr plan",
  },
  {
    key: "location",
    heading: "Location",
  },
  {
    key: "next_location",
    heading: "Next Location",
  },
  {
    key: "current_depth",
    heading: "Current Depth (ft)",
  },
  {
    key: "last_csg_size",
    heading: "Last Csg Size",
  },
  {
    key: "md",
    heading: "MD",
  },
  {
    key: "tvd",
    heading: "TVD",
  },
  {
    key: "liner_size",
    heading: "Liner Size",
  },
  {
    key: "tol",
    heading: "TOL",
  },
  {
    key: "linear_size_md",
    heading: "Liner Size MD",
  },
  {
    key: "linear_size_tvd",
    heading: "Liner Size TVD",
  },
  {
    key: "prev_depth",
    heading: "Prev. Depth (ft)",
  },
  {
    key: "days_since_spud_comm_date",
    heading: "Days Since Spud/Comm (Date)",
  },
  {
    key: "cric",
    heading: "Circ %",
  },
  {
    key: "daily_footage",
    heading: "Daily Footage (ft)",
  },
  {
    key: "distance_from_dha_mgate",
    heading: "Distance from DHA M/Gate (KM)",
  },
  {
    key: "foreman_remarks",
    heading: "Foreman Remarks",
  },
];
export const rigDetailsEditFields = [
  {
    name: "rig_number",
    placeholder: "Rig ID",
    label: "Rig ID",
  },
  {
    name: "report_date",
    placeholder: "Report Date",
    label: "Report Date",
  },
  {
    name: "well",
    placeholder: "Well",
    label: "Well",
  },
  {
    name: "charge",
    placeholder: "Charge No",
    label: "Charge No",
  },
  {
    name: "wellbores",
    placeholder: "Well Bores",
    label: "Well Bores",
  },
  {
    name: "rig_forman_vsat",
    placeholder: "Rig Foreman VSAT",
    label: "Rig Foreman VSAT",
  },
  {
    name: "contractor_clerk_vsat",
    placeholder: "Contractor/Clerk VSAT",
    label: "Contractor/Clerk VSAT",
  },
  {
    name: "manager",
    placeholder: "Manager",
    label: "Manager",
  },
  {
    name: "engineer",
    placeholder: "Engineer",
    label: "Engineer",
  },
  {
    name: "foreman",
    placeholder: "Foreman",
    label: "Foreman",
  },
  {
    name: "objective",
    placeholder: "Objective",
    label: "Objective",
  },
  {
    name: "circ",
    placeholder: "Circ",
    label: "Circ",
  },
  {
    name: "current_depth",
    placeholder: "Current Depth",
    label: "Current Depth",
  },
  {
    name: "daily_footage",
    placeholder: "Daily Footage",
    label: "Daily Footage",
  },
  {
    name: "days_since_spud_comm_date",
    placeholder: "Days Since Spud Comm Date",
    label: "Days Since Spud Comm Date",
  },
  {
    name: "distance_from_dha_mgate",
    placeholder: "Distance from DHA Mgate",
    label: "Distance from DHA Mgate",
  },
  {
    name: "last_24_hr_operation",
    placeholder: "Last 24Hr Operation",
    label: "Last 24Hr Operation",
  },
  {
    name: "last_csg_size",
    placeholder: "Last CSG Size",
    label: "Last CSG Size",
  },
  {
    name: "liner_size",
    placeholder: "Linear Size",
    label: "Linear Size",
  },
  {
    name: "location",
    placeholder: "Location",
    label: "Location",
  },
  {
    name: "md",
    placeholder: "MD",
    label: "MD",
  },
  {
    name: "next_24_hr_plan",
    placeholder: "Next 24 Hr Plan",
    label: "Next 24 Hr Plan",
  },
  {
    name: "next_location",
    placeholder: "Next Location",
    label: "Next Location",
  },
  {
    name: "prev_depth",
    placeholder: "Prev Depth",
    label: "Prev Depth",
  },
  {
    name: "tol",
    placeholder: "TOL",
    label: "TOL",
  },
  {
    name: "tvd",
    placeholder: "TVD",
    label: "TVD",
  },
  {
    name: "linear_size_md",
    placeholder: "Linear Size MD",
    label: "Linear Size MD",
  },
  {
    name: "linear_size_tvd",
    placeholder: "Linear Size TVD",
    label: "Linear Size TVD",
  },

  {
    name: "foreman_remarks",
    placeholder: "Foreman Remarks",
    label: "Foreman Remarks",
  },
];
export const operationDetailsColumn = [
  "From-To",
  "Hrs",
  "Lateral",
  "Phase",
  "Cat",
  "Major Op",
  "Actions",
  "Object",
  "Resp Co",
  // "Hole Depth Start",
  // "Hole Depth End",
  // "Event Depth Start",
  // "Event Depth End",
  "Start",
  "End",
  "Start",
  "End",
  "LT Type",
  "LT ID",
  "Summary Of Operations",
  "Action",
];
export const operationDetailsSpan = [
  { id: "", label: "", colspan: 9 },
  // { id: "holeDepthStart", label: "Hole Depth Start", colspan: 2 },
  { id: "holeDepthEnd", label: "Hole Depth", colspan: 2 },
  { id: "eventDepthStart", label: "Event Depth", colspan: 2 },
  // { id: "eventDepthEnd", label: "Event Depth End", colspan: 1 },
];
export const operationEditFields = [
  {
    name: "from_to",
    placeholder: "From-To",
    label: "From-To",
  },
  {
    name: "hrs",
    placeholder: "Hrs",
    label: "Hrs",
    type: "number",
  },
  {
    name: "lateral",
    placeholder: "Lateral",
    label: "Lateral",
    // type: "number",
  },
  {
    name: "phase",
    placeholder: "Phase",
    label: "Phase",
  },
  {
    name: "cat",
    placeholder: "Cat",
    label: "Cat",
  },
  {
    name: "major_op",
    placeholder: "Major Op",
    label: "Major Op",
  },
  {
    name: "action",
    placeholder: "Action",
    label: "Action",
  },
  {
    name: "object",
    placeholder: "Object",
    label: "Object",
  },
  {
    name: "resp_co",
    placeholder: "Resp Co",
    label: "Resp Co",
  },
  {
    name: "hole_depth_start",
    placeholder: "Hole Depth Start",
    label: "Hole Depth Start",
  },
  {
    name: "hole_depth_end",
    placeholder: "Hole Depth End",
    label: "Hole Depth End",
  },
  {
    name: "event_depth_start",
    placeholder: "Event Depth Start",
    label: "Event Depth Start",
  },
  {
    name: "event_depth_end",
    placeholder: "Event Depth End",
    label: "Event Depth End",
  },
  {
    name: "lt_type",
    placeholder: "LT Type",
    label: "LT Type",
  },
  {
    name: "lt_id",
    placeholder: "LT ID",
    label: "LT ID",
    type: "number",
  },
  {
    name: "summary_of_operations",
    placeholder: "Summary Of Operations",
    label: "Summary Of Operations",
    multiline: true,
  },
];

export const lostTimeRigsColumn = [
  "#",
  "Rig ID",
  "Report Date",
  "Well",
  "Charge No",
  "Well Bores",
  "Rig Foreman VSAT",
  "Objective",
  "Action",
];
export const lostTimeSecondaryRigsColumn = [
  "View",
  "Drilling Contractor",
  "Rig ID",
  "Report Date",
  // newly added lost time col name
  "From Date",
  "Hours",
  "Cumulative Hours",
  "LT ID",
  "Parent LT ID",
  "Type",
  "Cause",
  "Object",
  "Resp Co",
  "Depth",
  "Lost Time Summary",
  // end new lostime kye
  "Well",

  "Charge No",
  "Well Bores",
  "Objective",
  // new
  "Foreman",
  "Last 24 Hr Operation",
  "Next 24 Hr Operation",
  "Location",
  "Next location",
  "Current Depth",
  "Last CSG Size",
  "MD",
  "TVD",
  "Linear Size",
  "TOL",
  "Linear Size MD",
  "Linear Size TVD",
  "Prev Depth",
  "Days Since Spud Comm Date",
  "Circ",
  "Daily Footage",
  "Distance from DHA Mgate",
  "Rig Foreman VSAT",
  "Action",
];
export const lostTimeDetailsColumn = [
  "From-Date",
  "Hours",
  "Cumulative Hours",
  "LT ID",
  "Parent LT ID",
  "LT Type",
  "Cause",
  "Object",
  "Resp Co",
  "Depth",
  "Lost Time Summary",
  "Action",
];
export const lostEditFields = [
  {
    name: "from_date",
    placeholder: "From-Date",
    label: "From-Date",
  },
  {
    name: "hours",
    placeholder: "Hours",
    label: "Hours",
    type: "number",
  },
  {
    name: "cumulative_hours",
    placeholder: "Cumulative Hours",
    label: "Cumulative Hours",
    type: "number",
  },
  {
    name: "lt_id",
    placeholder: "LT ID",
    label: "Lt ID",
    type: "number",
  },
  {
    name: "parent_lt_id",
    placeholder: "Parent LT ID",
    label: "Parent LT ID",
    type: "number",
  },
  {
    name: "type",
    placeholder: " LT Type",
    label: "LT Type",
  },
  {
    name: "cause",
    placeholder: "Cause",
    label: "Cause",
  },
  {
    name: "resp_co",
    placeholder: "Resp Co",
    label: "Resp Co",
  },
  {
    name: "depth",
    placeholder: "Depth",
    label: "Depth",
  },
  {
    name: "lost_time_summary",
    placeholder: "Lost Time Summary",
    label: "Lost Time Summary",
    multiline: true,
  },
];

export const activityLogsColumn = [
  "User Name",
  "Action",
  "Description",
  "User IP",
  "Log Time",
];
export const activityLogsColumnKeys = {
  "Log Time": "created_at",
};

export const userColumn = ["User Name", "Email", "Role", "Action"];
export const userCell = [
  {
    key: "id",
  },
  {
    key: "name",
  },
  {
    key: "email",
  },
  {
    key: "role_name",
  },
  {
    key: "Action",
  },
];

export const cardList = [
  {
    title: "Number of Reports Parsed",
    src: reportProceed,
    key: "reportProcessCount",
    dateKey: "minReportDate",
  },
  {
    title: "YTD Operation Time (Hours)",
    src: clock,
    key: "operationHours",
  },
  {
    title: "YTD LT Overall (Hours)",
    subTitle: "LT Overall (Percentage)",
    subTitleKey: "LTOverAllpercentage",
    src: lostTimeRigs,
    key: "lossTimeOverallHours",
  },
  {
    title: "YTD LT (Hours)",
    subTitle: "LT Rigs (Percentage)",
    subTitleKey: "LTRigspercentage",
    src: calender,
    key: "lossTimeRigsHours",
  },
];

export const rigInfo = [
  "Rig Number",
  "Hp",
  "Rig Provider",
  "Ops Dep",
  "Head Of Dept",
  // "Land OffShore",
  "Rig Type",
  "Drilling Contractor",
  "Action",
];

export const BitDataColumn = [
  "Average ROP Bit HHP",
  "Bearings",
  "Bit Number",
  "DC",
  "Gauge",
  "Gpm",
  "Hours DC Av",
  "I-Row",
  "IADC",
  "Jet Vel",
  "Jets",
  "Location",
  "Manufacturer",
  "MD In",
  "MD Out",
  "O-Row",
  "Other",
  "Pooh Reason",
  "Pressure",
  "RPM",
  "Run Footage DP Av",
  "Serial Number",
  "Size",
  "TFA",
  "Type",
  "WOB",
  // "rig_id",
  "Action",
];

export const bulkDataColumn = [
  "Barite (SX)",
  "Bentonite (SX)",
  "Cement G (SX)",
  "Drill Water (BBLS)",
  "Fuel (BBLS)",
  "Pot Water (BBLS)",
  "Action",
];
export const drillStringTableColumn = [
  "Bottom Thread",
  "Class",
  "Component",
  "Grade",
  "ID Size",
  "Joints",
  "Length",
  "Nominal Size",
  "OD Size",
  "Drill Order",
  "Provider",
  "Serial",
  "Top Thread",
  "Weight",
  "Action",
];
export const drillStringDataColumn = [
  "BHA Hours",
  "Drill Float",
  "Jars Hours",
  "Pick Up",
  "Rot Torque",
  "Jars Serial",
  "Shock Sub Serial",
  "Mud Motor Serial",
  "Mud Motor Hrs",
  "Shock Sub Hours",
  "Slack Off",
  "String Weight",
  "Action",
];

export const miscellaneousDataColumn = [
  "BOP Drill",
  "BOP Test",
  "DSLTA",
  "Safety Meeting",
  "Sea",
  "Weather",
  "Wind",
  "Action",
];

export const mudDataColumn = [
  "% LGS",
  "3/6 RPM",
  "CA (PPM)",
  "CL (PPM)",
  "Cake API",
  "Elec Stability",
  "FL Temp",
  "Filtrate WL API",
  "Filtrate WL HTHP",
  "Funnel Vis. (SEC)",
  "Gels Sec Min",
  "MBT",
  "Mud Type",
  "Oil Vol. %",
  "PH",
  "PPT Spurt",
  "PPT Total",
  "PV",
  "Sand Vol. %",
  "Solids Vol. %",
  "Water Vol. %",
  "YP",
  "Cake HTHP",
  "Weight",
  "Action",
];
export const personnelServicesDataColumn = [
  "Category",
  "Company",
  "No of Persons",
  "Operating Hours",
  "Personnel on Loc Hrs",
  "Total",
  "Action",
];
export const mudTreatmentDataColumn = [
  "CaCO3 25",
  "CaCO3 50",
  "Ca(OH)₂",
  "E-Control RD",
  "MRBL F",
  "Rethk",
  "CACL2-98",
  "NF25",
  "MRBL-M",
  "PACLV",
  "CITRICACID",
  "POLY-SAL",
  "MAX-GUARD",
  "NEWDRLPLUS",
  "RAPIDSWEEP",
  "BENT",
  "PACR",
  "DUOVIS-NS",
  "Action",
];

export const trucksAndBoatsDataColumn = [
  "Standby/Tankers",
  "Others 1",
  "Others 2",
  "Others 3",
  "Others 4",
  "Action",
];

export const dailySurveyColumn = [
  "Lateral",
  "Survey MD",
  "Angle",
  "Azimuth",
  "CLength",
  "TVD",
  "N/S(-)Coordinate",
  "E/W(-)Coordinate",
  "Vertical Sec",
  "DLS",
  "Action",
];

export const months = [
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
];

export const missingReportsColumn = ["Date", "Count"];
export const missingReportsColumnKeys = {
  Date: "missing_report_date",
};

export const dailyActiveRigsColumnByYear = ["Report Date", "Count"];

export const lostTimeEmptyColumns = {
  cause: null,
  cumulative_hours: null,
  depth: null,
  from_date: null,
  hours: null,
  lost_time_summary: null,
  lt_id: null,
  object: null,
  parent_lt_id: null,
  resp_co: null,
  type: null,
  rig_id: null,
};

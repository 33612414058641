import { Backdrop } from "@mui/material";
import loadIcon from "../../assets/images/dashboard_logo.png";

const Splash = () => {
  return (
    <Backdrop
      open={true}
      component="main"
      sx={{
        color: "common.white",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <img src={loadIcon} alt="loader" />
    </Backdrop>
  );
};

export default Splash;

import { styled, TableContainer } from "@mui/material";

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    "&::-webkit-scrollbar": {
      width: "10px",
      height: "12px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1", // color in scroll bar
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#01221E",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  
    "& .MuiTable-root": {
      borderCollapse: "separate",
      borderSpacing: "0px 10px",
      [theme.breakpoints.down("xl")]: {
        borderSpacing: "0px 5px",
      },
    },
    //*************header************* */
    "& .MuiTableHead-root": {
      "& .MuiTableRow-root": {
        "& .MuiTableCell-root": {
          color: theme.typography.body3.color,
          fontWeight: theme.typography.body3.fontWeight,
          fontSize: theme.typography.body3.fontSize,
          padding: "6px 16px 6px 10px",
          borderBottom: "0px",
          "& .cellText": {
            whiteSpace: "nowrap",
            textAlign: "left",
            fontSize: theme.typography.body3.fontSize,
          },
        },
      },
    },
    //*************body************* */
    "& .MuiTableBody-root": {
      borderRadius: "10px",
      "& .MuiTableRow-root": {
        backgroundColor: "#ECECEC",
        // borderBottom: "14px solid #ffff",
  
        "& .MuiTableCell-root": {
          color: theme.typography.body4.color,
          fontWeight: theme.typography.body4.fontWeight,
          fontSize: theme.typography.body4.fontSize,
          padding: "6px 16px 6px 10px",
          "& .cellText": {
            whiteSpace: "nowrap",
            textAlign: "left",
            fontSize: theme.typography.body4.fontSize,
          },
  
          // Apply border-radius to the first child
          "&:first-child": {
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
          },
          // // Apply border-radius to the last child
          "&:last-child": {
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
          },
        },
      },
    },
  }));
import { PuffLoader } from "react-spinners";

const ApiLoader = ({ height, marginTop }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: marginTop || "2rem",
        height: height,
      }}
    >
      <PuffLoader
        color="rgba(242, 95, 43, 1)"
        cssOverride={{}}
        loading
        size={75}
        speedMultiplier={2}
      />
    </div>
  );
};

export default ApiLoader;

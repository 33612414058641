import { LoadingButton } from "@mui/lab";
import styled from "styled-components";

const ButtonWrapper = styled(LoadingButton)(() => ({
  boxShadow: "none !important",
  // fontWeight: "800",
  // fontSize: "0.875rem",
}));

const Button = ({
  label,
  onClick,
  type,
  disabled,
  loading,
  variant,
  color,
  endIcon,
  startIcon,
  size,
  sx,
  isDecoration = false,
  disablePadding = false,
  smallPadding = false,
  fontSize,
  isbg = false,
  fit = false,
  style
}) => {
  return (
    <ButtonWrapper
      onClick={onClick}
      type={type}
      disabled={disabled}
      variant={variant}
      loadingPosition="center"
      loading={loading ? loading : false}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      fullWidth
      size={size}
      sx={
        sx || {
          background: isbg
            ? ""
            : "linear-gradient(96.1deg, #F25F2B 0%, #FFC410 143.93%)",
          color: color ? "" : "#fff",
          padding: disablePadding ? "0" :smallPadding ? "3px" : "normal",
          textDecoration: isDecoration ? "underline" : "",
          fontSize: fontSize ? fontSize : " 18px",
          textTransform: "capitalize",
          // width:"fit-content"
          width: fit ? "fit-content" : "100%",
          ...style
        }
      }
    >
      {label}
    </ButtonWrapper>
  );
};

export default Button;

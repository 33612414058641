import { TableCell, Typography } from "@mui/material";
import CustomTooltip from "./CustomTooltip";

const TableCellWithText = ({ text, sx, onClick, error, message }) => {
  if (error) {
    return (
      <TableCell variant="body" align="center" sx={sx}>
        <CustomTooltip title={message}>
          <Typography
            className="cellText"
            onClick={onClick}
            sx={{ color: "red", cursor: "pointer" }}
          >
            {text !== null && text !== undefined && text !== "" ? text : "-"}
          </Typography>
        </CustomTooltip>
      </TableCell>
    );
  } else {
    return (
      <TableCell variant="body" align="center" sx={sx}>
        <Typography className="cellText" onClick={onClick}>
          {text !== null && text !== undefined && text !== "" ? text : "-"}
        </Typography>
      </TableCell>
    );
  }
};

export default TableCellWithText;

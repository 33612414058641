import { useEffect, useState } from "react";
import axiosInstance from "../../services/api";
import { Toaster } from "../../components/common/Toaster";
import { useAuth } from "../../store/ContextProvider";

const useAPI = (
  url,
  page,
  rowsPerPage,
  searchQuery,
  startDate,
  endDate,
  rigId,
  wellId,
  query,
  shouldExecute = true,
  contractor
) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { setIsSessionExpired } = useAuth();

  useEffect(() => {
    if (shouldExecute) {
      fetchData();
    }
  }, [
    url,
    page,
    rowsPerPage,
    searchQuery,
    startDate,
    endDate,
    rigId,
    wellId,
    shouldExecute,
    contractor,
    query
  ]);

  // get data
  const fetchData = () => {
    let queryParams = {};
    queryParams = {
      page: page + 1,
      recordsPerPage: rowsPerPage,
      search: searchQuery,
      startDate,
      endDate,
      rigId,
      wellId,
      contractor,
      ...query,
    };
    setError(null);
    setLoading(true);

    axiosInstance
      .get(url, {
        params: queryParams,
      })
      .then((response) => {
        setData(response);
      })
      .catch((err) => {
        // **************************session expired modal************
        if (err?.response?.status === 401) {
          setIsSessionExpired(true);
        }

        console.log("err", err);
        try {
          const { status, data } = err?.response;
          if (status) {
            // handleErrorResponse(status, data.msg);
          }
        } catch {
          console.log(err);
          //   enqueueSnackbar("Your session has been expired", {
          //     variant: "error",
          //   });
        }
      })
      .finally(() => setLoading(false));
  };
  // get data by Id
  const fetchDataById = (id) => {
    const queryParams = {
      page: page + 1,
      recordsPerPage: rowsPerPage,
      search: query,
    };
    setError(null);
    setLoading(true);

    axiosInstance
      .get(url + `/${id}`, {
        params: queryParams,
      })
      .then((response) => setData(response))
      .catch((err) => {
        try {
          const { status, data } = err?.response;
          if (status) {
            // handleErrorResponse(status, data.msg);
          }
        } catch {
          console.log(err);
          //   enqueueSnackbar("Your session has been expired", {
          //     variant: "error",
          //   });
        }
      })
      .finally(() => setLoading(false));
  };
  // delete data
  const deleteData = (id) => {
    setError(null);
    setLoading(true);
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(url + (id ? `${id}` : ""))
        .then((response) => {
          const { status, statusText } = response;
          if (status === 200 && statusText === "OK") {
            setLoading(false);
            Toaster("success", response.data.message);
            resolve(response);
          }
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            setIsSessionExpired(true);
            return;
          }
          const { status, data } = err?.response;
          Toaster("error", data?.message);
          reject(err);
        })
        .finally(() => setLoading(false));
    });
  };
  // post data
  const postData = (payload) => {
    setError(null);
    setLoading(true);
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(url, payload)
        .then((response) => {
          const { status, statusText } = response;
          if (statusText === "Created") {
            setLoading(false);
            resolve(response);
          } else if (status) {
            // we do this if we don not call fetch data on post
            setLoading(false);
            Toaster("success", response.data.message);
            resolve(response);
          }
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            setIsSessionExpired(true);
            return;
          }
          try {
            const { status, data } = err?.response;
            if (status) {
              Toaster("error", data.message);
              reject(err);
            }
          } catch (err) {
            console.log(err);
            reject(err);
          }
        })
        .finally(() => setLoading(false));
    });
  };
  // put data
  const putData = (payload) => {
    setError(null);
    setLoading(true);
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(url, payload)
        .then((response) => {
          const { status, statusText } = response;
          if (statusText === "Created") {
            setLoading(false);
            resolve(response);
          } else if (status) {
            // we do this if we don not call fetch data on post
            setLoading(false);
            Toaster("success", response.data.message);
            resolve(response);
          }
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            setIsSessionExpired(true);
            return;
          }
          try {
            const { status, data } = err?.response;
            if (status) {
              Toaster("error", data.message);
              reject(err);
            }
          } catch (err) {
            console.log(err);
            reject(err);
          }
        })
        .finally(() => setLoading(false));
    });
  };
  // update data
  const updateData = (payload, id) => {
    setError(null);
    setLoading(true);

    return new Promise((resolve, reject) => {
      axiosInstance
        .patch(url + `/${id}`, payload)
        .then((response) => {
          const { status, statusText } = response;
          if (status === 200 && statusText === "OK") {
            setLoading(false);
            resolve(response);
            Toaster("success", response?.data?.message);
          }
          if (status === 201 && statusText === "OK") {
            setLoading(false);
            resolve(response);
            Toaster("success", response?.data?.message);
          }
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            setIsSessionExpired(true);
            return;
          }
          try {
            const { status, data } = err?.response;
            if (status) {
              Toaster("error", data?.message);
            }
          } catch {
            console.log(err);
          }
        })
        .finally(() => setLoading(false));
    });
  };

  return {
    data,
    loading,
    error,
    fetchData,
    deleteData,
    postData,
    updateData,
    putData,
    fetchDataById,
  };
};

export default useAPI;

import { Box, styled, Typography } from "@mui/material";
import ProfileInfo from "./ProfileInfo";
import ActionButton from "./ActionButton";
import Button from "./Button";

const HeaderBox = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
const PrimaryHeader = ({
  text,
  isProfile = false,
  marginBottom,
  isButton = false,
  onClick,
  color,
  IconComponent,
  isSecondaryButton = false,
  buttonLabel,
  buttonColor,
  ButtonIconComponent,
  ButtonOnClick,
  variant,
  sx,
}) => {
  return (
    <HeaderBox
      sx={{
        marginBottom: marginBottom,
        mb: { xs: 5, md: 5 },
        ...sx,
      }}
    >
      <Typography
        variant="h1"
        component={"h1"}
        sx={{
          fontSize: {
            xs: "2rem",
            sm: "2.5rem",
            // md: "3rem",
            xl: "3.5rem",
          },
        }}
      >
        {text}
      </Typography>
      {isProfile ? <ProfileInfo alt={"profile"} title={"Habibi"} /> : ""}

      <Box sx={{ display: "flex" }}>
        {isSecondaryButton && (
          <Button
            label={buttonLabel}
            color={buttonColor}
            variant={variant}
            size={"large"}
            sx={{ maxWidth: "100px" }}
            startIcon={ButtonIconComponent}
            onClick={ButtonOnClick}
          />
        )}
        {isButton && (
          <ActionButton
            IconComponent={IconComponent}
            onClick={onClick}
            color={color}
          />
        )}
      </Box>
    </HeaderBox>
  );
};

export default PrimaryHeader;

import { lazy } from "react";
import MissingReports from "../pages/MissingReports";
import DailyActiveRigs from "../pages/DailyActiveRigs";

const NotFound = lazy(() => import("../components/NotFound"));
const PageNotFound = lazy(() => import("../components/PageNotFound"));
// const Register = lazy(() => import("../components/Register"));
const RigDetailsEdit = lazy(() =>
  import("../components/Rigs/RigDetails/RigDetailsEdit")
);
const Dashboard = lazy(() => import("../pages/Dashboard"));
const ExportRigs = lazy(() => import("../pages/ExportRigs"));
const Login = lazy(() => import("../pages/Login"));
const Logs = lazy(() => import("../pages/Logs"));
const Rigs = lazy(() => import("../pages/Rigs"));
const AllRigs = lazy(() => import("../pages/Rigs/AllRigs"));
const UploadedRigs = lazy(() => import("../pages/Rigs/UploadedRigs"));
const Summary = lazy(() => import("../pages/Rigs/UploadedRigs/summary"));
const LostTimeRigs = lazy(() => import("../pages/Rigs/LostTimeRigs"));
const RigDetails = lazy(() => import("../pages/Rigs/RigDetails"));
const UploadReport = lazy(() => import("../pages/UploadReport"));
const Users = lazy(() => import("../components/Register"));
// const UserRegisterForm = lazy(() => import("../components/Register/UserRegisterForm"));
const UserTable = lazy(() => import("../components/Register/UserTable"));
const RigInfo = lazy(() => import("../components/RigInfo"));

export const publicRoutesList = [
  {
    path: "/",
    component: <Login />,
  },
  {
    path: "*",
    component: <NotFound />,
  },
];

export const privateRoutesList = [
  {
    path: "/uploadReport",
    component: <UploadReport />,
    permissionKey: "upload_report",
  },
  {
    path: "/exportRigs",
    component: <ExportRigs />,
    permissionKey: "export_rigs",
  },
  {
    path: "/logs",
    component: <Logs />,
    permissionKey: "logs",
  },
  {
    path: "/allRigs",
    component: <AllRigs />,
    permissionKey: "all_rigs",
  },
  {
    path: "/uploadedRigs",
    component: <UploadedRigs />,
    permissionKey: "lost_time_rigs",
  },
  {
    path: "/uploadedRigs/summary",
    component: <Summary />,
    permissionKey: "lost_time_rigs",
  },
  {
    path: "/lostTimeRigs",
    component: <LostTimeRigs />,
    permissionKey: "lost_time_rigs",
  },
  {
    path: "/rigs",
    component: <Rigs />,
    permissionKey: "rigs",
  },
  {
    path: "/rigsDetails/:id",
    component: <RigDetails />,
    permissionKey: "rig_details",
  },
  {
    path: "/dashboard",
    component: <Dashboard />,
    permissionKey: "dashboard",
  },
  // {
  //   path: "/operationDetails/:id", //not in use
  //   component: <OperationEdit />,
  // },
  {
    path: "/RigDetailsEdit/:id",
    component: <RigDetailsEdit />,
    permissionKey: "rig_detail_edit",
  },
  {
    path: "/user",
    component: <UserTable />,
    permissionKey: "user",
  },
  {
    path: "/user/register",
    component: <Users />,
    permissionKey: "register_users",
  },
  {
    path: "/rigInfo",
    component: <RigInfo />,
    permissionKey: "rig_info",
  },
  {
    path: "/pageNotFound",
    component: <PageNotFound />,
    permissionKey: "",
  },
  {
    path: "/missingReports",
    component: <MissingReports />,
    permissionKey: "missing_reports",
  },
  {
    path: "/daily-active-rigs",
    component: <DailyActiveRigs />,
    permissionKey: "daily_active_rigs",
  },
];

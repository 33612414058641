import { IconButton } from "@mui/material";
const ActionButton = ({ color, size, IconComponent, onClick, sx }) => {
  return (
    <IconButton
      color={color}
      size={size}
      onClick={onClick}
      sx={
        sx || {
          padding: "0px",
        }
      }
    >
      <IconComponent />
    </IconButton>
  );
};

export default ActionButton;

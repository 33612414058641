import React from "react";
import {
  Box,
  FormHelperText,
  MenuItem,
  Select,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const DropDown = ({
  value,
  onChange,
  name,
  helperText,
  options,
  errorText,
  error,
  size,
  defaultValue,
  disabled,
  selectedText,
  mb,
  labelMb,
  labelMt,
  labelFontSize,
  fontSize,
  onClear,
  isShowClear = false,
  isInlineBlock = true,
}) => {
  return (
    <Box mb={mb} sx={{ position: "relative" }}>
      <label
        style={{
          color: "#889898",
          marginBottom: labelMb || "10px",
          marginTop: labelMt || "10px",
          display: isInlineBlock ? "inline-block" : "",
          fontSize: labelFontSize,
        }}
      >
        {helperText}
      </label>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Select
          value={value}
          onChange={onChange}
          variant="outlined"
          fullWidth
          defaultValue={defaultValue}
          size={size ? size : "small"}
          name={name}
          displayEmpty
          disabled={disabled}
          error={errorText ? true : false}
          sx={{
            "& .MuiInputBase-input": {
              padding: "8.5px 14px",
            },
            backgroundColor: "#ECECEC",
            fontSize: fontSize || "1rem",
          }}
        >
          <MenuItem disabled value="">
            {selectedText}
          </MenuItem>
          {options}
        </Select>

        {value && isShowClear && (
          <IconButton
            onClick={onClear}
            sx={{ marginLeft: "-45px", zIndex: 1 }}
            size="small"
          >
            <ClearIcon fontSize="small" sx={{ fontSize: "1rem" }} />
          </IconButton>
        )}
      </Box>

      <FormHelperText sx={{ color: error ? "#E27878" : "" }}>
        {errorText}
      </FormHelperText>
    </Box>
  );
};

export default DropDown;

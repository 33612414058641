import { useEffect, useMemo, useState } from "react";
import useAPI from "../../utils/hooks/useApi";
import { ENDPOINTS } from "../../utils/constant";
import TableCellWithText from "../common/TableCellWithText";
import PrimaryHeader from "../common/PrimaryHeader";
import SearchAndDrop from "../common/SearchAndDrop";
import ApiLoader from "../ApiLoader";
import TableBig from "../common/TableBig";
import {
  missingReportsColumn,
  missingReportsColumnKeys,
} from "../../utils/constant/columns";
import { TableRow } from "@mui/material";
import { useDebounce } from "../../utils/hooks/useDebounce";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { exportToExcel } from "../../utils/helpers";

const MissingReports = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(1);
  const [query, setQuery] = useState("");
  const [lostTimeInfo, setLostTime] = useState([]);
  const [downloadData, setDownloadData] = useState("");
  const [sort, setSort] = useState("asc");
  const [sortKeyName, setSortKey] = useState("");
  const [sortIndex, setSortIndex] = useState(null);
  const searchQuery = useDebounce(query, 200);
  const sortQuery = useMemo(() => {
    return {
      sort,
      sortKeyName,
    };
  }, [sort, sortKeyName]);
  const { data: lostTimeResponse, loading: lostTimeListLoader } = useAPI(
    `${ENDPOINTS.MISSING_REPORTS}`,
    page,
    rowsPerPage,
    searchQuery,
    "",
    "",
    "",
    "",
    sortQuery,
    true
  );
  const { data: downloadResponse, loading: downloadLoader } = useAPI(
    `${ENDPOINTS.MISSING_REPORTS}`
  );

  useEffect(() => {
    if (lostTimeResponse) {
      const { status, statusText, data } = lostTimeResponse;
      const { rows, count } = data || {};
      if (status === 200 && statusText === "OK") {
        setLostTime(rows);
        setTotalRecords(count);
      }
    }
  }, [lostTimeResponse]);
  useEffect(() => {
    if (downloadResponse) {
      const { status, statusText, data } = downloadResponse;
      if (status === 200 && statusText === "OK") {
        setDownloadData(data);
      }
    }
  }, [downloadResponse]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleSearch = (e) => {
    setPage(0);
    setQuery(e.target.value);
  };

  const handleDownload = () => {
    if (Array.isArray(downloadData)) {
      const updatedData = downloadData.map(({ d, date, ...rest }) => rest);
      exportToExcel(updatedData);
    }
  };

  const renderData = (data) => (
    <>
      <TableCellWithText text={data?.missing_report_date} />
      <TableCellWithText text={data?.count} />
    </>
  );

  return (
    <>
      {/* ********************Header************************** */}
      <PrimaryHeader
        text={"Missing Reports"}
        marginBottom={"1rem"}
        isSecondaryButton={true}
        buttonLabel={"Download"}
        ButtonIconComponent={<CloudDownloadIcon />}
        ButtonOnClick={handleDownload}
      />

      {/* ********************SearchAndDrop************************** */}
      {/* <SearchAndDrop
        handleSearch={handleSearch}
        searchSize={"small"}
        searchPlaceholder={"Search"}
        searchValue={query} // for fututre use
      /> */}

      {/* ********************TableBig************************** */}
      {lostTimeListLoader ? (
        <ApiLoader />
      ) : (
        <TableBig
          tableColumn={missingReportsColumn}
          showSortInCol={true}
          requiredIndex={[0]}
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={!totalRecords || totalRecords <= 0 ? 0 : page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalRecords={totalRecords}
          handleSort={(direction, index, columnName) => {
            setSort(direction);
            setSortIndex(index);
            setSortKey(missingReportsColumnKeys[columnName]);
          }}
          sortIndex={sortIndex}
          sortDirection={sort}
          tableBody={lostTimeInfo?.map((data, i) => (
            <TableRow key={i}>{renderData(data)}</TableRow>
          ))}
        />
      )}
    </>
  );
};

export default MissingReports;

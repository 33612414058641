import { createSlice } from "@reduxjs/toolkit";

const UserSlice = createSlice({
  name: "users",
  initialState: {
    users: null,
    paginationInfo: {
        page: 0,
        pageSize: 10,
      },
  },
  reducers: {
    saveUsers: (state, action) => {
      state.users = action.payload;
    },
    setPaginationPage: (state, action) => {
        state.paginationInfo.page = action.payload;
      },
      setPaginationPageSize: (state, action) => {
        state.paginationInfo.pageSize = action.payload;
      },
   
  },
});
export default UserSlice;
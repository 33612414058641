import { createSlice } from "@reduxjs/toolkit";

const LogsSlice = createSlice({
  name: "logs",
  initialState: {
    logs: null,
    paginationInfo: {
        page: 0,
        pageSize: 10,
      },
  },
  reducers: {
    saveLogs: (state, action) => {
      state.logs = action.payload;
    },
    setPaginationPage: (state, action) => {
        state.paginationInfo.page = action.payload;
      },
      setPaginationPageSize: (state, action) => {
        state.paginationInfo.pageSize = action.payload;
      },
   
  },
});
export default LogsSlice;
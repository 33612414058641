import axios from "axios";
import { baseUrl, token } from "../utils/constant";
import { Toaster } from "../components/common/Toaster";

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: "Bearer " + token,
  },
  // timeout: 60000,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve access token from local storage
    const accessToken = localStorage.getItem("access_token");

    // Set the access token in the Authorization header
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;

    // Check if error response due to server being off
    if (error.message === "Network Error") {
      Toaster(
        "error",
        "Server is currently unavailable. Please try again later."
      );
      return Promise.reject(
        error ? error : { msg: "Unable to get response from server" }
      );
    }

    // Check if the error response is due to an expired access token
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return Promise.reject(
        error ? error : { msg: "Your session has expired" }
      );
    }

    return Promise.reject(error ? error : { msg: "Your session has expired" });
  }
);

export default axiosInstance;

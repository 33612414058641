// AuthContext.js
import React, { createContext, useContext, useState } from "react";
import { getUserDetails } from "../utils/helpers";

export const AuthContext = createContext();

export const ContextProvider = ({ children }) => {
  const userDetails = getUserDetails();
  const [userData, setUserData] = useState(userDetails);
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  const setLoginUserDetails = (user) => {
    setUserData(user);
  };

  return (
    <AuthContext.Provider
      value={{
        setLoginUserDetails,
        userData,
        setIsSessionExpired,
        isSessionExpired,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

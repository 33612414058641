import "./App.css";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import Routes from "./routes";
import { ToastProvider } from "./components/common/ToastContainer";
import { ContextProvider } from "./store/ContextProvider";
import { Provider } from "react-redux";
import { store } from "./store/store";

function App() {
  return (
    <>
    <Provider store={store}>

      <ContextProvider>
        <ThemeProvider theme={theme}>
          <ToastProvider />
          <Routes />
        </ThemeProvider>
      </ContextProvider>
    </Provider>
    </>
  );
}

export default App;

export const baseUrl = process.env.REACT_APP_BASE_URL;
export const pdfBaseUrl = process.env.REACT_APP_PDF_BASE_URL;
export const token = localStorage.getItem("access_token");
export const fileFormat = "data:application/pdf";
export const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;
export const IV = process.env.REACT_APP_IV;
export const downloadCsvFilePath = process.env.REACT_APP_DOWNLOAD_CSV_PATH;
export const ENDPOINTS = {
  RIGS: "rigs",
  ALL_RIGS: "rigs/allRigs",
  RIGS_DETAILS: "rigs/rigDetails",
  RIGS_DROP_DOWN: "rigs/rigsDropDown",
  WELL_DROP_DOWN: "rigs/allWellsDropDown",
  EXPORT: "rigs/export",
  OPERATION: "operation",
  OPERATION_BY_ID: "operation/operationDetails",
  LOSTTIME: "lostTime",
  All_LOST_TIME: "lostTime/allLostTime",
  LOST_TIME_BY_ID: "lostTime/lostTimeDetails",
  DASHBOARD: "dashboard",
  ROLE: "role",
  USER: "user",
  USER_REGISTER: "user/register",
  LOGS: "logs",
  RIGS_INFO: "rigInfo",
  DRILLING_CONTRACTOR: "rigInfo/contractor",
  REPORT: "report",
  BIT_DATA: "bitData",
  BULK_DATA: "bulkData",
  DRIL_STRING_TABLE: "drillStringTable",
  DRILL_STRING_DATA: "drillStringData",
  MISCELLANEOUS_DATA: "miscellaneousData",
  MUD_DATA: "mudData",
  PERSONAL_SERVICE_INFO: "personnelServicesInfo",
  TREATEMENT_DATA: "treatmentData",
  TRUCK_AND_BOATS: "trucksBoatsData",
  DAILY_SURVEY: "dailySurvey",
  SHORE: "rigInfo/landOffShoreDropdown",
  MISSING_REPORTS: "report/missing",
  MIN_REPORT_DATE: "report/min",
  DAILY_ACTIVE_RIGS: "rigs/dailyActiveRigs",
};

export const operationDetailsColumnaddrow = {
  action: null,
  cat: null,
  event_depth_end: null,
  event_depth_start: null,
  from_to: null,
  hole_depth_end: null,
  hole_depth_start: null,
  hrs: "",
  lateral: null,
  lt_id: null,
  lt_type: null,
  major_op: "",
  object: null,
  phase: null,
  resp_co: null,
  summary_of_operations: null,
  rig_id: "",
};

export const rigInfoCsvHeaders = {
  id: "id",
  rig_number: "rig_number",
  hp: "hp",
  rig_provider: "rig_provider",
  ops_dep: "ops_dep",
  land_offshore: "rig_type",
  head_of_dep: "head_of_dep",
  drilling_contractor: "drilling_contractor",
};
export const uploadedRigsHeader = [
  "Report date",
  "Report Name",
  "Warnings",
  "Action",
];
export const uploadedRigsSummaryHeader = [
  "Drilling Contractor",
  "Rig ID",
  "No Of Errors",
  "Action",
];

export const hasValueMessage = "Please fill in at least one field.";

import { Outlet, Navigate, useNavigate } from "react-router-dom";
import DashboardLayout from "../layout/DashboardLayout";
import { useAuth } from "../store/ContextProvider";
import SessionExpiredModal from "../components/common/ConfirmModal";
import { RestoreIcon } from "../assets/icons";

const PrivateRoutes = () => {
  const user = localStorage.getItem("access_token");
  const { isSessionExpired, setIsSessionExpired } = useAuth();
  const navigate = useNavigate();
  return user ? (
    <DashboardLayout>
      <SessionExpiredModal
        open={isSessionExpired}
        title={"Session Timed Out !"}
        content={"Your session has expired. Please log in again to continue."}
        confirmLabel={"Login"}
        IconImage={RestoreIcon}
        handleConfirm={() => {
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          navigate("/");
          setIsSessionExpired(false);
        }}
      />
      <Outlet />
    </DashboardLayout>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoutes;

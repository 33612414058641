import React from "react";
import { Avatar, Typography, Box } from "@mui/material";

const ProfileInfo = ({ src, sizes, alt, title }) => {
  return (
    <Box display="flex" alignItems="center">
      <Avatar sizes={sizes} src={src} alt={alt} variant="circular" />
      <Typography
        variant="h6"
        style={{ marginLeft: "1rem", textTransform: "capitalize" }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default ProfileInfo;

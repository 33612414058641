import { createTheme } from "@mui/material/styles";
import RadioGrotesk from "../assets/font/RadioGrotesk-Regular.otf";
import KumbhSans from "../assets/font/KumbhSans.ttf";

// Create a CSS @font-face rule for the new font
const radioGroteskFont = {
  fontFamily: "RadioGrotesk",
  src: `
    url(${RadioGrotesk}) format('opentype')
  `,
};
const kumbhSansFont = {
  fontFamily: "KumbhSans",
  src: `
    url(${KumbhSans}) format('truetype')
  `,
};
const theme = createTheme({
  palette: {
    primary: {
      light: "#fff", // ---
      main: "rgba(242, 95, 43, 1)", // bg
      dark: "rgba(242, 95, 43, 1)", // hover
      contrastText: "#fff", // text
    },
    secondary: {
      light: "#fff", // ---
      main: "rgba(242, 95, 43, 1)", // bg
      dark: "#d35d49", // hover
      contrastText: "#fff", // text
    },
    error: {
      light: "",
      main: "#F81C1E", // bg
      dark: "", // hover
      contrastText: "#fff", // text
    },
    info: { // clear all button
      light: "",
      main: "#01221E", // bg
      dark: "rgba(242, 95, 43, 1)", // hover
      contrastText: "#ffff", // text
    },
    default: {
      light: "",
      main: "#DBF8EF", // bg
      dark: "", // hover
      contrastText: "#50D1AA", // text
    },
    addCard: {
      light: "#005490",
      main: "#005490", // bg
      dark: "#005490", // hover
      contrastText: "#005490", // text
    },
    favicon: {
      light: "#005490",
      main: "#fff", // bg
      dark: "", // hover
      contrastText: "#005490", // text
    },
    faviconOrang: {
      light: "#EA7C69",
      main: "#fff", // bg
      dark: "", // hover
      contrastText: "#EA7C69", // text
    },
    cancelBtn: {
      light: "",
      main: "#667085", // bg
      dark: "", // hover
      contrastText: "#263A62", // text
    },
  },

  typography: {
    fontFamily: ["KumbhSans"].join(","),
    fontSize: 14,
    h1: {
      //used as a main heading of each page
      fontSize: "3rem",
      fontWeight: 400,
      fontFamily: "RadioGrotesk",
    },
    h2: {
      //use as a cards value
      fontSize: "1.8rem",
      fontWeight: 600,
    },
    h3: {
      //use as a sub headings
      fontSize: "4rem",
      fontWeight: 400,
      fontFamily: "RadioGrotesk",
    },
    h4: {
      //use as a card title
      fontSize: "0.93rem",
      fontWeight: 400,
    },
    h5: {
      fontSize: "30px",
      fontWeight: 400,
      color: "#000",
    },
    body2: {
      // use for mini table cell
      fontSize: "0.8125rem",
      fontWeight: 400,
      color: "#000000",
    },
    body3: {
      // use for big table head headings
      fontSize: "1rem",
      fontWeight: 700,
      color: "#777777",
    },
    body4: {
      // use for big table cell 
      fontSize: "13px",
      fontWeight: 400,
      color: "#000000",
    },

    minitableButton: {
      //use for mini table button
      fontSize: "0.8125rem",
      fontWeight: 500,
      color: "#F25F2B",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'RadioGrotesk';
          src: url(${RadioGrotesk}) format('opentype');
        }
        @font-face {
          font-family: 'KumbhSans';
          src: url(${KumbhSans}) format('truetype');
        }
      `,
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        "&.MuiTypography-minicell": {
          fontSize: "0.875rem", // 14px
          fontWeight: 400,
          color: "#000000",
        },
        "&.MuiTypography-body3": {
          fontSize: "1rem",
          fontWeight: 500,
          color: "#000000",
        },
        "&.MuiTypography-body4": {
          fontSize: "1rem",
          fontWeight: 400,
          color: "#000000",
        },
      },
    },
  },
});

export default theme;

import { createSlice } from "@reduxjs/toolkit";

const UploadPDFSlice = createSlice({
  name: "uploadPDF",
  initialState: {
    errors: null,
  },
  reducers: {
    saveErrors: (state, action) => {
      state.errors = action.payload;
    },
    
}
})
export default UploadPDFSlice;
import { createSlice } from "@reduxjs/toolkit";

const ContractorSlice = createSlice({
  name: "contractors",
  initialState: {
    contractors: null,
  },
  reducers: {
    saveContractors: (state, action) => {
      state.contractors = action.payload;
    },
   
  },
});
export default ContractorSlice;
import React from "react";
import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import { Box, FormHelperText, IconButton } from "@mui/material";
import { ClearIcon } from "../../assets/icons";

const TextInputWrapper = styled(TextField)(() => ({
  backgroundColor: "#ECECEC",
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#263A62",
    },
  },
  "& .MuiFormControl-root": {
    marginBottom: "0",
  },
}));

const TextInput = ({
  placeholder,
  type,
  onChange,
  name,
  value,
  InputProps,
  disabled,
  error,
  helperText,
  inputMode,
  label,
  size,
  multiline,
  maxRows,
  minRows,
  sx,
  inputProps,
  labelMb,
  labelMt,
  fontSize,
  labelFontSize,
  mb,
  isShowClear = false,
  onClear
}) => {
  return (
    <Box mb={mb} sx={{ position: "relative" }}>
      <label
        style={{
          color: "#889898",
          marginBottom: labelMb || "10px",
          marginTop: labelMt || "10px",
          // display: "inline-block",
          fontSize: labelFontSize,
        }}
      >
        {label}
      </label>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TextInputWrapper
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          name={name}
          value={value}
          InputProps={InputProps}
          // inputProps={inputProps}
          disabled={disabled}
          error={helperText ? true : false}
          inputMode={inputMode}
          fullWidth
          size={size ? size : "small"}
          color="primary"
          multiline={multiline ? multiline : false}
          maxRows={maxRows}
          inputProps={{
            sx: {
              fontSize: fontSize || "16px",
              "&.MuiOutlinedInput-notchedOutline": {
                fontSize: fontSize || "16px",
              },
            },
          }}
          minRows={minRows}
          sx={sx}
          autoComplete="false"
        />
        {value && isShowClear && (
          <IconButton
            onClick={onClear}
            sx={{ marginLeft: "-50px", zIndex: 1 }}
            size="small"
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
      <FormHelperText sx={{ color: error ? "#E27878" : "" }}>
        {helperText}
      </FormHelperText>
    </Box>
  );
};

export default TextInput;

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Button from "./Button";
const DialogContainer = styled(Dialog)(() => ({
  // width: "100%",
  // maxWidth: "none",
  // "& .MuiPaper-root": {
  //   maxWidth: "60%",
  //   maxHeight: "100vh",
  // },
  "& Dialog": {
    "& h3": {
      // textAlign: "center",
      padding: "",
    },
  },
  "& .MuiFormControl-root": {
    // marginBottom: "1rem",
  },
  "& .fieldBox": {
    "& Button": {
      marginTop: "10px",
    },
  },
}));

const ConfirmModal = ({
  open,
  title,
  loading,
  content,
  handleConfirm,
  handleCancel,
  confirmLabel,
  IconImage,
}) => {
  const style = {
    width: { sm: 400 },
    padding: "25px 10px 5px 10px",
    textAlign: "center",

    "& h2": {
      textAlign: "center",
      padding: "5px 0 0 0",
      margin: "1rem 0 1rem 0",
      // color: "#005490",
      fontWeight: "700",
      fontSize: "28px",
    },
  };
  return (
    <DialogContainer
      open={open}
      //   onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: { md: "60%" },
          maxHeight: "100vh",
          margin: { xs: "16px", sm: "32px" },
        },
      }}
    >
      <Box sx={style}>
        <IconButton
          color="error"
          sx={{
            fill: "#e99797",
            shadowRadius: 10,
            fontSize: "40px",
            // boxShadow: " 0px -2px 40px 5px rgba(233,151,151,0.67);",
            padding: "0px",
          }}
        >
          <IconImage
            sx={{
              fill: "#e99797",
              shadowRadius: 10,

              fontSize: { xs: "50px", sm: "70px" },
            }}
          />
        </IconButton>
        {title && <DialogTitle color={"info"}>{title}</DialogTitle>}

        {content && (
          <DialogContent>
            <div className="fieldBox">{content}</div>
          </DialogContent>
        )}

        <DialogActions>
          {handleConfirm ? (
            <Button
              label={confirmLabel || "Delete"}
              color={"primary"}
              variant={"contained"}
              size={"medium"}
              sx={{}}
              onClick={handleConfirm}
              loading={loading}
            />
          ) : (
            ""
          )}

          {handleCancel ? (
            <Button
              label={"Cancel"}
              color={"info"}
              variant={"contained"}
              size={"medium"}
              sx={{}}
              onClick={handleCancel}
            />
          ) : (
            ""
          )}
        </DialogActions>
      </Box>
    </DialogContainer>
  );
};

export default ConfirmModal;

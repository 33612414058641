import { toast } from "react-toastify";

export const Toaster = (messageType, message) => {
  return toast[messageType](message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    toastId: message,
  });
};

import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import TextInput from "./TextInput";
import { Grid, InputAdornment } from "@mui/material";
import DropDown from "./DropDwon";

const SearchAndDrop = ({
  handleSearch,
  handleDropDownPrimary,
  handleDropDownSecondary,
  searchPlaceholder,
  searchValue,
  searchSize,
  primaryOptions,
  secondaryOptions,
  primarySelectedText,
  secondarySelectedText,
  primaryValue,
  secondaryValue,
  handleDropDownTertiary,
  tertiaryOptions,
  tertiarySelectedText,
  tertiaryValue,
  mb,
  isShowPrimaryDropDown = false,
  isShowSecondaryDropDown = false,
  isShowTertiaryDropDown = false,
  handleClearPrimary,
  handleClearSecondary,
  handleClearTertiary,
  isShowClearPrimary,
  isShowClearSecondary,
  isShowClearTertiary,
  isShowSerach = true,
  showClearSearch = false,
  handleClearSearch,
}) => {
  return (
    <Grid
      container
      justifyContent={isShowSerach ? "end" : ""}
      columnSpacing={2}
      mb={mb}
      rowSpacing={{ xs: 2, sm: 2, lg: 0 }}
    >
      {/* ******************search********************** */}
      {isShowSerach ? (
        <Grid item xs={12} sm={6} lg={6}>
          <TextInput
            placeholder={searchPlaceholder}
            size={searchSize}
            value={searchValue}
            isShowClear={showClearSearch}
            onClear={handleClearSearch}
            InputProps={{
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                </>
              ),
            }}
            onChange={handleSearch}
          />
        </Grid>
      ) : (
        ""
      )}

      {/* ******************primary drop down********************** */}
      <Grid item xs={12} sm={6} lg={2}>
        {isShowPrimaryDropDown && (
          <DropDown
            onChange={handleDropDownPrimary}
            options={primaryOptions}
            selectedText={primarySelectedText}
            value={primaryValue}
            onClear={handleClearPrimary}
            isShowClear={isShowClearPrimary}
            isInlineBlock={false}
          />
        )}
      </Grid>
      {/* ******************secondary drop down********************** */}
      <Grid item xs={12} sm={6} lg={2}>
        {isShowSecondaryDropDown && (
          <DropDown
            onChange={handleDropDownSecondary}
            options={secondaryOptions}
            selectedText={secondarySelectedText}
            value={secondaryValue}
            onClear={handleClearSecondary}
            isShowClear={isShowClearSecondary}
            isInlineBlock={false}
          />
        )}
      </Grid>
      {/* ******************tertiary drop down********************** */}
      <Grid item xs={12} sm={6} lg={2}>
        {isShowTertiaryDropDown && (
          <DropDown
            onChange={handleDropDownTertiary}
            options={tertiaryOptions}
            selectedText={tertiarySelectedText}
            value={tertiaryValue}
            onClear={handleClearTertiary}
            isShowClear={isShowClearTertiary}
            isInlineBlock={false}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default SearchAndDrop;

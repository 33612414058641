import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
export const drawerList = [
  {
    text: "Dashboard",
    icon: DashboardIcon,
    to: "/dashboard",
    permissionKey: "dashboard",
  },
  {
    text: "Upload Report",
    icon: DashboardIcon,
    to: "/uploadReport",
    permissionKey: "upload_report",
  },
  {
    text: "Rigs",
    icon: DashboardIcon,
    // to: null,
    permissionKey: "rigs",
    extended: false,
    nestedList: [
      {
        text: "All Rigs",
        icon: DashboardIcon,
        to: "/allRigs",
      },

      {
        text: "Lost Time Rigs",
        icon: DashboardIcon,
        to: "/lostTimeRigs",
      },
    ],
  },
  {
    text: "Export Reports",
    icon: DashboardIcon,
    to: "/exportRigs",
    permissionKey: "export_rigs",
  },
  {
    text: "Logs",
    icon: DashboardIcon,
    to: "/logs",
    permissionKey: "logs",
  },
  {
    text: "User",
    icon: DashboardIcon,
    to: "/user",
    permissionKey: "user",
  },
  {
    text: "Rig Info",
    icon: DashboardIcon,
    to: "/rigInfo",
    permissionKey: "rig_info",
  },
];

export const logOut = [
  {
    text: "Log Out",
    icon: LogoutIcon,
    to: null,
  },
];
